import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"
import Note from "../../../../components/note"
import { getCourseNavigation } from "../../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/im-sueden-sommerlich"
        />
      }
      navigation={navigation}
    >
      <Seo title="Woran erkennt man ein Gedicht" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 1
            </Heading>
            <Heading as="h2" level={2}>
              Woran erkennt man ein Gedicht
            </Heading>
          </Stack>
          <Paragraph>
            Woran erkennt man ein Gedicht? Daran, dass es sich reimt? Daran,
            dass es kurz ist? Daran, dass so wenige Wörter benutzt werden?
          </Paragraph>
          <Note variant="task">
            Bei dieser Übung ist dein Spürsinn gefragt. Handelt es sich bei den
            folgenden Beispielen um ein Gedicht oder um eine andere Textform?
            Schritt für Schritt lernst du dabei Merkmale kennen, die ein Gedicht
            auszeichnen.
          </Note>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
